/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../app/API.service";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createPay = /* GraphQL */ `mutation CreatePay(
  $input: CreatePayInput!
  $condition: ModelPayConditionInput
) {
  createPay(input: $input, condition: $condition) {
    id
    amount
    typeChange
    balance
    date
    voucherURL
    ShippingType {
      id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    PayType {
      id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    sellID
    updatedBy
    createdBy
    isDollar
    createdAt
    updatedAt
    payShippingTypeId
    payPayTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePayMutationVariables,
  APITypes.CreatePayMutation
>;
export const updatePay = /* GraphQL */ `mutation UpdatePay(
  $input: UpdatePayInput!
  $condition: ModelPayConditionInput
) {
  updatePay(input: $input, condition: $condition) {
    id
    amount
    typeChange
    balance
    date
    voucherURL
    ShippingType {
      id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    PayType {
      id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    sellID
    updatedBy
    createdBy
    isDollar
    createdAt
    updatedAt
    payShippingTypeId
    payPayTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePayMutationVariables,
  APITypes.UpdatePayMutation
>;
export const deletePay = /* GraphQL */ `mutation DeletePay(
  $input: DeletePayInput!
  $condition: ModelPayConditionInput
) {
  deletePay(input: $input, condition: $condition) {
    id
    amount
    typeChange
    balance
    date
    voucherURL
    ShippingType {
      id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    PayType {
      id
      name
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    sellID
    updatedBy
    createdBy
    isDollar
    createdAt
    updatedAt
    payShippingTypeId
    payPayTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePayMutationVariables,
  APITypes.DeletePayMutation
>;
export const createBillInfo = /* GraphQL */ `mutation CreateBillInfo(
  $input: CreateBillInfoInput!
  $condition: ModelBillInfoConditionInput
) {
  createBillInfo(input: $input, condition: $condition) {
    id
    name
    address
    identification
    email
    createdBy
    updatedBy
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBillInfoMutationVariables,
  APITypes.CreateBillInfoMutation
>;
export const updateBillInfo = /* GraphQL */ `mutation UpdateBillInfo(
  $input: UpdateBillInfoInput!
  $condition: ModelBillInfoConditionInput
) {
  updateBillInfo(input: $input, condition: $condition) {
    id
    name
    address
    identification
    email
    createdBy
    updatedBy
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBillInfoMutationVariables,
  APITypes.UpdateBillInfoMutation
>;
export const deleteBillInfo = /* GraphQL */ `mutation DeleteBillInfo(
  $input: DeleteBillInfoInput!
  $condition: ModelBillInfoConditionInput
) {
  deleteBillInfo(input: $input, condition: $condition) {
    id
    name
    address
    identification
    email
    createdBy
    updatedBy
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBillInfoMutationVariables,
  APITypes.DeleteBillInfoMutation
>;
export const createComment = /* GraphQL */ `mutation CreateComment(
  $input: CreateCommentInput!
  $condition: ModelCommentConditionInput
) {
  createComment(input: $input, condition: $condition) {
    id
    message
    createdBy
    updatedBy
    quoteID
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    date
    createdAt
    updatedAt
    commentUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentMutationVariables,
  APITypes.CreateCommentMutation
>;
export const updateComment = /* GraphQL */ `mutation UpdateComment(
  $input: UpdateCommentInput!
  $condition: ModelCommentConditionInput
) {
  updateComment(input: $input, condition: $condition) {
    id
    message
    createdBy
    updatedBy
    quoteID
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    date
    createdAt
    updatedAt
    commentUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentMutationVariables,
  APITypes.UpdateCommentMutation
>;
export const deleteComment = /* GraphQL */ `mutation DeleteComment(
  $input: DeleteCommentInput!
  $condition: ModelCommentConditionInput
) {
  deleteComment(input: $input, condition: $condition) {
    id
    message
    createdBy
    updatedBy
    quoteID
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    date
    createdAt
    updatedAt
    commentUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentMutationVariables,
  APITypes.DeleteCommentMutation
>;
export const createSetting = /* GraphQL */ `mutation CreateSetting(
  $input: CreateSettingInput!
  $condition: ModelSettingConditionInput
) {
  createSetting(input: $input, condition: $condition) {
    id
    imgs
    gain
    minimumCost
    purchaseCost
    weightRate
    createdBy
    updatedBy
    deliveryCost
    internTax
    feeWeight
    feeWeightClient
    iva
    dollar
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSettingMutationVariables,
  APITypes.CreateSettingMutation
>;
export const updateSetting = /* GraphQL */ `mutation UpdateSetting(
  $input: UpdateSettingInput!
  $condition: ModelSettingConditionInput
) {
  updateSetting(input: $input, condition: $condition) {
    id
    imgs
    gain
    minimumCost
    purchaseCost
    weightRate
    createdBy
    updatedBy
    deliveryCost
    internTax
    feeWeight
    feeWeightClient
    iva
    dollar
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSettingMutationVariables,
  APITypes.UpdateSettingMutation
>;
export const deleteSetting = /* GraphQL */ `mutation DeleteSetting(
  $input: DeleteSettingInput!
  $condition: ModelSettingConditionInput
) {
  deleteSetting(input: $input, condition: $condition) {
    id
    imgs
    gain
    minimumCost
    purchaseCost
    weightRate
    createdBy
    updatedBy
    deliveryCost
    internTax
    feeWeight
    feeWeightClient
    iva
    dollar
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSettingMutationVariables,
  APITypes.DeleteSettingMutation
>;
export const createFeedback = /* GraphQL */ `mutation CreateFeedback(
  $input: CreateFeedbackInput!
  $condition: ModelFeedbackConditionInput
) {
  createFeedback(input: $input, condition: $condition) {
    id
    message
    stars
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFeedbackMutationVariables,
  APITypes.CreateFeedbackMutation
>;
export const updateFeedback = /* GraphQL */ `mutation UpdateFeedback(
  $input: UpdateFeedbackInput!
  $condition: ModelFeedbackConditionInput
) {
  updateFeedback(input: $input, condition: $condition) {
    id
    message
    stars
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFeedbackMutationVariables,
  APITypes.UpdateFeedbackMutation
>;
export const deleteFeedback = /* GraphQL */ `mutation DeleteFeedback(
  $input: DeleteFeedbackInput!
  $condition: ModelFeedbackConditionInput
) {
  deleteFeedback(input: $input, condition: $condition) {
    id
    message
    stars
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFeedbackMutationVariables,
  APITypes.DeleteFeedbackMutation
>;
export const createSellDetail = /* GraphQL */ `mutation CreateSellDetail(
  $input: CreateSellDetailInput!
  $condition: ModelSellDetailConditionInput
) {
  createSellDetail(input: $input, condition: $condition) {
    id
    sellID
    Product {
      id
      name
      link
      linkLocation
      cost
      quantity
      weight
      imgURL
      height
      width
      details
      guideWEB
      WeightType {
        id
        name
        abbreviation
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      ProductType {
        id
        name
        createdBy
        updatedBy
        Tax {
          id
          amount
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        productTypeTaxId
        productTypeSystemCodeId
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      quoteID
      price
      createdBy
      updatedBy
      ProductStatus {
        id
        name
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      surcharge
      discount
      createdAt
      updatedAt
      productWeightTypeId
      productProductTypeId
      productSystemCodeId
      productProductStatusId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    sellDetailProductId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSellDetailMutationVariables,
  APITypes.CreateSellDetailMutation
>;
export const updateSellDetail = /* GraphQL */ `mutation UpdateSellDetail(
  $input: UpdateSellDetailInput!
  $condition: ModelSellDetailConditionInput
) {
  updateSellDetail(input: $input, condition: $condition) {
    id
    sellID
    Product {
      id
      name
      link
      linkLocation
      cost
      quantity
      weight
      imgURL
      height
      width
      details
      guideWEB
      WeightType {
        id
        name
        abbreviation
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      ProductType {
        id
        name
        createdBy
        updatedBy
        Tax {
          id
          amount
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        productTypeTaxId
        productTypeSystemCodeId
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      quoteID
      price
      createdBy
      updatedBy
      ProductStatus {
        id
        name
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      surcharge
      discount
      createdAt
      updatedAt
      productWeightTypeId
      productProductTypeId
      productSystemCodeId
      productProductStatusId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    sellDetailProductId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSellDetailMutationVariables,
  APITypes.UpdateSellDetailMutation
>;
export const deleteSellDetail = /* GraphQL */ `mutation DeleteSellDetail(
  $input: DeleteSellDetailInput!
  $condition: ModelSellDetailConditionInput
) {
  deleteSellDetail(input: $input, condition: $condition) {
    id
    sellID
    Product {
      id
      name
      link
      linkLocation
      cost
      quantity
      weight
      imgURL
      height
      width
      details
      guideWEB
      WeightType {
        id
        name
        abbreviation
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      ProductType {
        id
        name
        createdBy
        updatedBy
        Tax {
          id
          amount
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        productTypeTaxId
        productTypeSystemCodeId
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      quoteID
      price
      createdBy
      updatedBy
      ProductStatus {
        id
        name
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      surcharge
      discount
      createdAt
      updatedAt
      productWeightTypeId
      productProductTypeId
      productSystemCodeId
      productProductStatusId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    sellDetailProductId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSellDetailMutationVariables,
  APITypes.DeleteSellDetailMutation
>;
export const createSell = /* GraphQL */ `mutation CreateSell(
  $input: CreateSellInput!
  $condition: ModelSellConditionInput
) {
  createSell(input: $input, condition: $condition) {
    id
    date
    amount
    createadBy
    updatedBy
    SellDetails {
      items {
        id
        sellID
        Product {
          id
          name
          link
          linkLocation
          cost
          quantity
          weight
          imgURL
          height
          width
          details
          guideWEB
          WeightType {
            id
            name
            abbreviation
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          ProductType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            productTypeTaxId
            productTypeSystemCodeId
            __typename
          }
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          quoteID
          price
          createdBy
          updatedBy
          ProductStatus {
            id
            name
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          surcharge
          discount
          createdAt
          updatedAt
          productWeightTypeId
          productProductTypeId
          productSystemCodeId
          productProductStatusId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        sellDetailProductId
        __typename
      }
      nextToken
      __typename
    }
    discount
    BillInfo {
      id
      name
      address
      identification
      email
      createdBy
      updatedBy
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    balance
    Pays {
      items {
        id
        amount
        typeChange
        balance
        date
        voucherURL
        ShippingType {
          id
          name
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        PayType {
          id
          name
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        sellID
        updatedBy
        createdBy
        isDollar
        createdAt
        updatedAt
        payShippingTypeId
        payPayTypeId
        __typename
      }
      nextToken
      __typename
    }
    typeChange
    createdAt
    updatedAt
    sellBillInfoId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSellMutationVariables,
  APITypes.CreateSellMutation
>;
export const updateSell = /* GraphQL */ `mutation UpdateSell(
  $input: UpdateSellInput!
  $condition: ModelSellConditionInput
) {
  updateSell(input: $input, condition: $condition) {
    id
    date
    amount
    createadBy
    updatedBy
    SellDetails {
      items {
        id
        sellID
        Product {
          id
          name
          link
          linkLocation
          cost
          quantity
          weight
          imgURL
          height
          width
          details
          guideWEB
          WeightType {
            id
            name
            abbreviation
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          ProductType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            productTypeTaxId
            productTypeSystemCodeId
            __typename
          }
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          quoteID
          price
          createdBy
          updatedBy
          ProductStatus {
            id
            name
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          surcharge
          discount
          createdAt
          updatedAt
          productWeightTypeId
          productProductTypeId
          productSystemCodeId
          productProductStatusId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        sellDetailProductId
        __typename
      }
      nextToken
      __typename
    }
    discount
    BillInfo {
      id
      name
      address
      identification
      email
      createdBy
      updatedBy
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    balance
    Pays {
      items {
        id
        amount
        typeChange
        balance
        date
        voucherURL
        ShippingType {
          id
          name
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        PayType {
          id
          name
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        sellID
        updatedBy
        createdBy
        isDollar
        createdAt
        updatedAt
        payShippingTypeId
        payPayTypeId
        __typename
      }
      nextToken
      __typename
    }
    typeChange
    createdAt
    updatedAt
    sellBillInfoId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSellMutationVariables,
  APITypes.UpdateSellMutation
>;
export const deleteSell = /* GraphQL */ `mutation DeleteSell(
  $input: DeleteSellInput!
  $condition: ModelSellConditionInput
) {
  deleteSell(input: $input, condition: $condition) {
    id
    date
    amount
    createadBy
    updatedBy
    SellDetails {
      items {
        id
        sellID
        Product {
          id
          name
          link
          linkLocation
          cost
          quantity
          weight
          imgURL
          height
          width
          details
          guideWEB
          WeightType {
            id
            name
            abbreviation
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          ProductType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            productTypeTaxId
            productTypeSystemCodeId
            __typename
          }
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          quoteID
          price
          createdBy
          updatedBy
          ProductStatus {
            id
            name
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          surcharge
          discount
          createdAt
          updatedAt
          productWeightTypeId
          productProductTypeId
          productSystemCodeId
          productProductStatusId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        sellDetailProductId
        __typename
      }
      nextToken
      __typename
    }
    discount
    BillInfo {
      id
      name
      address
      identification
      email
      createdBy
      updatedBy
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    balance
    Pays {
      items {
        id
        amount
        typeChange
        balance
        date
        voucherURL
        ShippingType {
          id
          name
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        PayType {
          id
          name
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        sellID
        updatedBy
        createdBy
        isDollar
        createdAt
        updatedAt
        payShippingTypeId
        payPayTypeId
        __typename
      }
      nextToken
      __typename
    }
    typeChange
    createdAt
    updatedAt
    sellBillInfoId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSellMutationVariables,
  APITypes.DeleteSellMutation
>;
export const createPayType = /* GraphQL */ `mutation CreatePayType(
  $input: CreatePayTypeInput!
  $condition: ModelPayTypeConditionInput
) {
  createPayType(input: $input, condition: $condition) {
    id
    name
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePayTypeMutationVariables,
  APITypes.CreatePayTypeMutation
>;
export const updatePayType = /* GraphQL */ `mutation UpdatePayType(
  $input: UpdatePayTypeInput!
  $condition: ModelPayTypeConditionInput
) {
  updatePayType(input: $input, condition: $condition) {
    id
    name
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePayTypeMutationVariables,
  APITypes.UpdatePayTypeMutation
>;
export const deletePayType = /* GraphQL */ `mutation DeletePayType(
  $input: DeletePayTypeInput!
  $condition: ModelPayTypeConditionInput
) {
  deletePayType(input: $input, condition: $condition) {
    id
    name
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePayTypeMutationVariables,
  APITypes.DeletePayTypeMutation
>;
export const createShippingType = /* GraphQL */ `mutation CreateShippingType(
  $input: CreateShippingTypeInput!
  $condition: ModelShippingTypeConditionInput
) {
  createShippingType(input: $input, condition: $condition) {
    id
    name
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShippingTypeMutationVariables,
  APITypes.CreateShippingTypeMutation
>;
export const updateShippingType = /* GraphQL */ `mutation UpdateShippingType(
  $input: UpdateShippingTypeInput!
  $condition: ModelShippingTypeConditionInput
) {
  updateShippingType(input: $input, condition: $condition) {
    id
    name
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShippingTypeMutationVariables,
  APITypes.UpdateShippingTypeMutation
>;
export const deleteShippingType = /* GraphQL */ `mutation DeleteShippingType(
  $input: DeleteShippingTypeInput!
  $condition: ModelShippingTypeConditionInput
) {
  deleteShippingType(input: $input, condition: $condition) {
    id
    name
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteShippingTypeMutationVariables,
  APITypes.DeleteShippingTypeMutation
>;
export const createAddress = /* GraphQL */ `mutation CreateAddress(
  $input: CreateAddressInput!
  $condition: ModelAddressConditionInput
) {
  createAddress(input: $input, condition: $condition) {
    id
    placeName
    province
    canton
    district
    codePostal
    reciverName
    reciverLastName
    isDefault
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    addressUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAddressMutationVariables,
  APITypes.CreateAddressMutation
>;
export const updateAddress = /* GraphQL */ `mutation UpdateAddress(
  $input: UpdateAddressInput!
  $condition: ModelAddressConditionInput
) {
  updateAddress(input: $input, condition: $condition) {
    id
    placeName
    province
    canton
    district
    codePostal
    reciverName
    reciverLastName
    isDefault
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    addressUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAddressMutationVariables,
  APITypes.UpdateAddressMutation
>;
export const deleteAddress = /* GraphQL */ `mutation DeleteAddress(
  $input: DeleteAddressInput!
  $condition: ModelAddressConditionInput
) {
  deleteAddress(input: $input, condition: $condition) {
    id
    placeName
    province
    canton
    district
    codePostal
    reciverName
    reciverLastName
    isDefault
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    addressUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAddressMutationVariables,
  APITypes.DeleteAddressMutation
>;
export const createProduct = /* GraphQL */ `mutation CreateProduct(
  $input: CreateProductInput!
  $condition: ModelProductConditionInput
) {
  createProduct(input: $input, condition: $condition) {
    id
    name
    link
    linkLocation
    cost
    quantity
    weight
    imgURL
    height
    width
    details
    guideWEB
    WeightType {
      id
      name
      abbreviation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    ProductType {
      id
      name
      createdBy
      updatedBy
      Tax {
        id
        amount
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      productTypeTaxId
      productTypeSystemCodeId
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    quoteID
    price
    createdBy
    updatedBy
    ProductStatus {
      id
      name
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    surcharge
    discount
    createdAt
    updatedAt
    productWeightTypeId
    productProductTypeId
    productSystemCodeId
    productProductStatusId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductMutationVariables,
  APITypes.CreateProductMutation
>;
export const updateProduct = /* GraphQL */ `mutation UpdateProduct(
  $input: UpdateProductInput!
  $condition: ModelProductConditionInput
) {
  updateProduct(input: $input, condition: $condition) {
    id
    name
    link
    linkLocation
    cost
    quantity
    weight
    imgURL
    height
    width
    details
    guideWEB
    WeightType {
      id
      name
      abbreviation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    ProductType {
      id
      name
      createdBy
      updatedBy
      Tax {
        id
        amount
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      productTypeTaxId
      productTypeSystemCodeId
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    quoteID
    price
    createdBy
    updatedBy
    ProductStatus {
      id
      name
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    surcharge
    discount
    createdAt
    updatedAt
    productWeightTypeId
    productProductTypeId
    productSystemCodeId
    productProductStatusId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductMutationVariables,
  APITypes.UpdateProductMutation
>;
export const deleteProduct = /* GraphQL */ `mutation DeleteProduct(
  $input: DeleteProductInput!
  $condition: ModelProductConditionInput
) {
  deleteProduct(input: $input, condition: $condition) {
    id
    name
    link
    linkLocation
    cost
    quantity
    weight
    imgURL
    height
    width
    details
    guideWEB
    WeightType {
      id
      name
      abbreviation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    ProductType {
      id
      name
      createdBy
      updatedBy
      Tax {
        id
        amount
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      productTypeTaxId
      productTypeSystemCodeId
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    quoteID
    price
    createdBy
    updatedBy
    ProductStatus {
      id
      name
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    surcharge
    discount
    createdAt
    updatedAt
    productWeightTypeId
    productProductTypeId
    productSystemCodeId
    productProductStatusId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductMutationVariables,
  APITypes.DeleteProductMutation
>;
export const createQuote = /* GraphQL */ `mutation CreateQuote(
  $input: CreateQuoteInput!
  $condition: ModelQuoteConditionInput
) {
  createQuote(input: $input, condition: $condition) {
    id
    comment
    date
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    QuoteStatus {
      id
      name
      order
      updatedBy
      createdBy
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      ActionByQuoteStatuses {
        items {
          id
          ActionQuoteStatus {
            id
            name
            description
            updatedBy
            createdBy
            createdAt
            updatedAt
            actionQuoteStatusSystemCodeId
            __typename
          }
          quotestatusID
          createdBy
          updatedBy
          createdAt
          updatedAt
          actionByQuoteStatusActionQuoteStatusId
          __typename
        }
        nextToken
        __typename
      }
      templateEmail
      isSaveTypeChange
      createdAt
      updatedAt
      quoteStatusSystemCodeId
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdBy
    updatedBy
    Products {
      items {
        id
        name
        link
        linkLocation
        cost
        quantity
        weight
        imgURL
        height
        width
        details
        guideWEB
        WeightType {
          id
          name
          abbreviation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        ProductType {
          id
          name
          createdBy
          updatedBy
          Tax {
            id
            amount
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          productTypeTaxId
          productTypeSystemCodeId
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        quoteID
        price
        createdBy
        updatedBy
        ProductStatus {
          id
          name
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        surcharge
        discount
        createdAt
        updatedAt
        productWeightTypeId
        productProductTypeId
        productSystemCodeId
        productProductStatusId
        __typename
      }
      nextToken
      __typename
    }
    Sell {
      id
      date
      amount
      createadBy
      updatedBy
      SellDetails {
        items {
          id
          sellID
          Product {
            id
            name
            link
            linkLocation
            cost
            quantity
            weight
            imgURL
            height
            width
            details
            guideWEB
            quoteID
            price
            createdBy
            updatedBy
            surcharge
            discount
            createdAt
            updatedAt
            productWeightTypeId
            productProductTypeId
            productSystemCodeId
            productProductStatusId
            __typename
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
          sellDetailProductId
          __typename
        }
        nextToken
        __typename
      }
      discount
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      balance
      Pays {
        items {
          id
          amount
          typeChange
          balance
          date
          voucherURL
          ShippingType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          PayType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          sellID
          updatedBy
          createdBy
          isDollar
          createdAt
          updatedAt
          payShippingTypeId
          payPayTypeId
          __typename
        }
        nextToken
        __typename
      }
      typeChange
      createdAt
      updatedAt
      sellBillInfoId
      __typename
    }
    SellShipping {
      id
      date
      amount
      createadBy
      updatedBy
      SellDetails {
        items {
          id
          sellID
          Product {
            id
            name
            link
            linkLocation
            cost
            quantity
            weight
            imgURL
            height
            width
            details
            guideWEB
            quoteID
            price
            createdBy
            updatedBy
            surcharge
            discount
            createdAt
            updatedAt
            productWeightTypeId
            productProductTypeId
            productSystemCodeId
            productProductStatusId
            __typename
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
          sellDetailProductId
          __typename
        }
        nextToken
        __typename
      }
      discount
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      balance
      Pays {
        items {
          id
          amount
          typeChange
          balance
          date
          voucherURL
          ShippingType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          PayType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          sellID
          updatedBy
          createdBy
          isDollar
          createdAt
          updatedAt
          payShippingTypeId
          payPayTypeId
          __typename
        }
        nextToken
        __typename
      }
      typeChange
      createdAt
      updatedAt
      sellBillInfoId
      __typename
    }
    Feedback {
      id
      message
      stars
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    isPayTotal
    Comments {
      items {
        id
        message
        createdBy
        updatedBy
        quoteID
        User {
          id
          name
          email
          phoneNumber
          imgURL
          isAdmin
          gender
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          identification
          identificationType
          createdBy
          updatedBy
          BillInfo {
            id
            name
            address
            identification
            email
            createdBy
            updatedBy
            phoneNumber
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          userSystemCodeId
          userBillInfoId
          __typename
        }
        date
        createdAt
        updatedAt
        commentUserId
        __typename
      }
      nextToken
      __typename
    }
    typeChange
    createdAt
    updatedAt
    quoteUserId
    quoteQuoteStatusId
    quoteSystemCodeId
    quoteSellId
    quoteSellShippingId
    quoteFeedbackId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuoteMutationVariables,
  APITypes.CreateQuoteMutation
>;
export const updateQuote = /* GraphQL */ `mutation UpdateQuote(
  $input: UpdateQuoteInput!
  $condition: ModelQuoteConditionInput
) {
  updateQuote(input: $input, condition: $condition) {
    id
    comment
    date
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    QuoteStatus {
      id
      name
      order
      updatedBy
      createdBy
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      ActionByQuoteStatuses {
        items {
          id
          ActionQuoteStatus {
            id
            name
            description
            updatedBy
            createdBy
            createdAt
            updatedAt
            actionQuoteStatusSystemCodeId
            __typename
          }
          quotestatusID
          createdBy
          updatedBy
          createdAt
          updatedAt
          actionByQuoteStatusActionQuoteStatusId
          __typename
        }
        nextToken
        __typename
      }
      templateEmail
      isSaveTypeChange
      createdAt
      updatedAt
      quoteStatusSystemCodeId
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdBy
    updatedBy
    Products {
      items {
        id
        name
        link
        linkLocation
        cost
        quantity
        weight
        imgURL
        height
        width
        details
        guideWEB
        WeightType {
          id
          name
          abbreviation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        ProductType {
          id
          name
          createdBy
          updatedBy
          Tax {
            id
            amount
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          productTypeTaxId
          productTypeSystemCodeId
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        quoteID
        price
        createdBy
        updatedBy
        ProductStatus {
          id
          name
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        surcharge
        discount
        createdAt
        updatedAt
        productWeightTypeId
        productProductTypeId
        productSystemCodeId
        productProductStatusId
        __typename
      }
      nextToken
      __typename
    }
    Sell {
      id
      date
      amount
      createadBy
      updatedBy
      SellDetails {
        items {
          id
          sellID
          Product {
            id
            name
            link
            linkLocation
            cost
            quantity
            weight
            imgURL
            height
            width
            details
            guideWEB
            quoteID
            price
            createdBy
            updatedBy
            surcharge
            discount
            createdAt
            updatedAt
            productWeightTypeId
            productProductTypeId
            productSystemCodeId
            productProductStatusId
            __typename
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
          sellDetailProductId
          __typename
        }
        nextToken
        __typename
      }
      discount
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      balance
      Pays {
        items {
          id
          amount
          typeChange
          balance
          date
          voucherURL
          ShippingType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          PayType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          sellID
          updatedBy
          createdBy
          isDollar
          createdAt
          updatedAt
          payShippingTypeId
          payPayTypeId
          __typename
        }
        nextToken
        __typename
      }
      typeChange
      createdAt
      updatedAt
      sellBillInfoId
      __typename
    }
    SellShipping {
      id
      date
      amount
      createadBy
      updatedBy
      SellDetails {
        items {
          id
          sellID
          Product {
            id
            name
            link
            linkLocation
            cost
            quantity
            weight
            imgURL
            height
            width
            details
            guideWEB
            quoteID
            price
            createdBy
            updatedBy
            surcharge
            discount
            createdAt
            updatedAt
            productWeightTypeId
            productProductTypeId
            productSystemCodeId
            productProductStatusId
            __typename
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
          sellDetailProductId
          __typename
        }
        nextToken
        __typename
      }
      discount
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      balance
      Pays {
        items {
          id
          amount
          typeChange
          balance
          date
          voucherURL
          ShippingType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          PayType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          sellID
          updatedBy
          createdBy
          isDollar
          createdAt
          updatedAt
          payShippingTypeId
          payPayTypeId
          __typename
        }
        nextToken
        __typename
      }
      typeChange
      createdAt
      updatedAt
      sellBillInfoId
      __typename
    }
    Feedback {
      id
      message
      stars
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    isPayTotal
    Comments {
      items {
        id
        message
        createdBy
        updatedBy
        quoteID
        User {
          id
          name
          email
          phoneNumber
          imgURL
          isAdmin
          gender
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          identification
          identificationType
          createdBy
          updatedBy
          BillInfo {
            id
            name
            address
            identification
            email
            createdBy
            updatedBy
            phoneNumber
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          userSystemCodeId
          userBillInfoId
          __typename
        }
        date
        createdAt
        updatedAt
        commentUserId
        __typename
      }
      nextToken
      __typename
    }
    typeChange
    createdAt
    updatedAt
    quoteUserId
    quoteQuoteStatusId
    quoteSystemCodeId
    quoteSellId
    quoteSellShippingId
    quoteFeedbackId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuoteMutationVariables,
  APITypes.UpdateQuoteMutation
>;
export const deleteQuote = /* GraphQL */ `mutation DeleteQuote(
  $input: DeleteQuoteInput!
  $condition: ModelQuoteConditionInput
) {
  deleteQuote(input: $input, condition: $condition) {
    id
    comment
    date
    User {
      id
      name
      email
      phoneNumber
      imgURL
      isAdmin
      gender
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      identification
      identificationType
      createdBy
      updatedBy
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userSystemCodeId
      userBillInfoId
      __typename
    }
    QuoteStatus {
      id
      name
      order
      updatedBy
      createdBy
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      ActionByQuoteStatuses {
        items {
          id
          ActionQuoteStatus {
            id
            name
            description
            updatedBy
            createdBy
            createdAt
            updatedAt
            actionQuoteStatusSystemCodeId
            __typename
          }
          quotestatusID
          createdBy
          updatedBy
          createdAt
          updatedAt
          actionByQuoteStatusActionQuoteStatusId
          __typename
        }
        nextToken
        __typename
      }
      templateEmail
      isSaveTypeChange
      createdAt
      updatedAt
      quoteStatusSystemCodeId
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdBy
    updatedBy
    Products {
      items {
        id
        name
        link
        linkLocation
        cost
        quantity
        weight
        imgURL
        height
        width
        details
        guideWEB
        WeightType {
          id
          name
          abbreviation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        ProductType {
          id
          name
          createdBy
          updatedBy
          Tax {
            id
            amount
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          productTypeTaxId
          productTypeSystemCodeId
          __typename
        }
        SystemCode {
          id
          value
          entity
          field
          label
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        quoteID
        price
        createdBy
        updatedBy
        ProductStatus {
          id
          name
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        surcharge
        discount
        createdAt
        updatedAt
        productWeightTypeId
        productProductTypeId
        productSystemCodeId
        productProductStatusId
        __typename
      }
      nextToken
      __typename
    }
    Sell {
      id
      date
      amount
      createadBy
      updatedBy
      SellDetails {
        items {
          id
          sellID
          Product {
            id
            name
            link
            linkLocation
            cost
            quantity
            weight
            imgURL
            height
            width
            details
            guideWEB
            quoteID
            price
            createdBy
            updatedBy
            surcharge
            discount
            createdAt
            updatedAt
            productWeightTypeId
            productProductTypeId
            productSystemCodeId
            productProductStatusId
            __typename
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
          sellDetailProductId
          __typename
        }
        nextToken
        __typename
      }
      discount
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      balance
      Pays {
        items {
          id
          amount
          typeChange
          balance
          date
          voucherURL
          ShippingType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          PayType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          sellID
          updatedBy
          createdBy
          isDollar
          createdAt
          updatedAt
          payShippingTypeId
          payPayTypeId
          __typename
        }
        nextToken
        __typename
      }
      typeChange
      createdAt
      updatedAt
      sellBillInfoId
      __typename
    }
    SellShipping {
      id
      date
      amount
      createadBy
      updatedBy
      SellDetails {
        items {
          id
          sellID
          Product {
            id
            name
            link
            linkLocation
            cost
            quantity
            weight
            imgURL
            height
            width
            details
            guideWEB
            quoteID
            price
            createdBy
            updatedBy
            surcharge
            discount
            createdAt
            updatedAt
            productWeightTypeId
            productProductTypeId
            productSystemCodeId
            productProductStatusId
            __typename
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
          sellDetailProductId
          __typename
        }
        nextToken
        __typename
      }
      discount
      BillInfo {
        id
        name
        address
        identification
        email
        createdBy
        updatedBy
        phoneNumber
        createdAt
        updatedAt
        __typename
      }
      balance
      Pays {
        items {
          id
          amount
          typeChange
          balance
          date
          voucherURL
          ShippingType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          PayType {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
            __typename
          }
          sellID
          updatedBy
          createdBy
          isDollar
          createdAt
          updatedAt
          payShippingTypeId
          payPayTypeId
          __typename
        }
        nextToken
        __typename
      }
      typeChange
      createdAt
      updatedAt
      sellBillInfoId
      __typename
    }
    Feedback {
      id
      message
      stars
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    isPayTotal
    Comments {
      items {
        id
        message
        createdBy
        updatedBy
        quoteID
        User {
          id
          name
          email
          phoneNumber
          imgURL
          isAdmin
          gender
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          identification
          identificationType
          createdBy
          updatedBy
          BillInfo {
            id
            name
            address
            identification
            email
            createdBy
            updatedBy
            phoneNumber
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          userSystemCodeId
          userBillInfoId
          __typename
        }
        date
        createdAt
        updatedAt
        commentUserId
        __typename
      }
      nextToken
      __typename
    }
    typeChange
    createdAt
    updatedAt
    quoteUserId
    quoteQuoteStatusId
    quoteSystemCodeId
    quoteSellId
    quoteSellShippingId
    quoteFeedbackId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuoteMutationVariables,
  APITypes.DeleteQuoteMutation
>;
export const createActionByQuoteStatus = /* GraphQL */ `mutation CreateActionByQuoteStatus(
  $input: CreateActionByQuoteStatusInput!
  $condition: ModelActionByQuoteStatusConditionInput
) {
  createActionByQuoteStatus(input: $input, condition: $condition) {
    id
    ActionQuoteStatus {
      id
      name
      description
      updatedBy
      createdBy
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      actionQuoteStatusSystemCodeId
      __typename
    }
    quotestatusID
    createdBy
    updatedBy
    createdAt
    updatedAt
    actionByQuoteStatusActionQuoteStatusId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateActionByQuoteStatusMutationVariables,
  APITypes.CreateActionByQuoteStatusMutation
>;
export const updateActionByQuoteStatus = /* GraphQL */ `mutation UpdateActionByQuoteStatus(
  $input: UpdateActionByQuoteStatusInput!
  $condition: ModelActionByQuoteStatusConditionInput
) {
  updateActionByQuoteStatus(input: $input, condition: $condition) {
    id
    ActionQuoteStatus {
      id
      name
      description
      updatedBy
      createdBy
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      actionQuoteStatusSystemCodeId
      __typename
    }
    quotestatusID
    createdBy
    updatedBy
    createdAt
    updatedAt
    actionByQuoteStatusActionQuoteStatusId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateActionByQuoteStatusMutationVariables,
  APITypes.UpdateActionByQuoteStatusMutation
>;
export const deleteActionByQuoteStatus = /* GraphQL */ `mutation DeleteActionByQuoteStatus(
  $input: DeleteActionByQuoteStatusInput!
  $condition: ModelActionByQuoteStatusConditionInput
) {
  deleteActionByQuoteStatus(input: $input, condition: $condition) {
    id
    ActionQuoteStatus {
      id
      name
      description
      updatedBy
      createdBy
      SystemCode {
        id
        value
        entity
        field
        label
        updatedBy
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      actionQuoteStatusSystemCodeId
      __typename
    }
    quotestatusID
    createdBy
    updatedBy
    createdAt
    updatedAt
    actionByQuoteStatusActionQuoteStatusId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteActionByQuoteStatusMutationVariables,
  APITypes.DeleteActionByQuoteStatusMutation
>;
export const createActionQuoteStatus = /* GraphQL */ `mutation CreateActionQuoteStatus(
  $input: CreateActionQuoteStatusInput!
  $condition: ModelActionQuoteStatusConditionInput
) {
  createActionQuoteStatus(input: $input, condition: $condition) {
    id
    name
    description
    updatedBy
    createdBy
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    actionQuoteStatusSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateActionQuoteStatusMutationVariables,
  APITypes.CreateActionQuoteStatusMutation
>;
export const updateActionQuoteStatus = /* GraphQL */ `mutation UpdateActionQuoteStatus(
  $input: UpdateActionQuoteStatusInput!
  $condition: ModelActionQuoteStatusConditionInput
) {
  updateActionQuoteStatus(input: $input, condition: $condition) {
    id
    name
    description
    updatedBy
    createdBy
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    actionQuoteStatusSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateActionQuoteStatusMutationVariables,
  APITypes.UpdateActionQuoteStatusMutation
>;
export const deleteActionQuoteStatus = /* GraphQL */ `mutation DeleteActionQuoteStatus(
  $input: DeleteActionQuoteStatusInput!
  $condition: ModelActionQuoteStatusConditionInput
) {
  deleteActionQuoteStatus(input: $input, condition: $condition) {
    id
    name
    description
    updatedBy
    createdBy
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    actionQuoteStatusSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteActionQuoteStatusMutationVariables,
  APITypes.DeleteActionQuoteStatusMutation
>;
export const createQuoteStatus = /* GraphQL */ `mutation CreateQuoteStatus(
  $input: CreateQuoteStatusInput!
  $condition: ModelQuoteStatusConditionInput
) {
  createQuoteStatus(input: $input, condition: $condition) {
    id
    name
    order
    updatedBy
    createdBy
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    ActionByQuoteStatuses {
      items {
        id
        ActionQuoteStatus {
          id
          name
          description
          updatedBy
          createdBy
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          actionQuoteStatusSystemCodeId
          __typename
        }
        quotestatusID
        createdBy
        updatedBy
        createdAt
        updatedAt
        actionByQuoteStatusActionQuoteStatusId
        __typename
      }
      nextToken
      __typename
    }
    templateEmail
    isSaveTypeChange
    createdAt
    updatedAt
    quoteStatusSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuoteStatusMutationVariables,
  APITypes.CreateQuoteStatusMutation
>;
export const updateQuoteStatus = /* GraphQL */ `mutation UpdateQuoteStatus(
  $input: UpdateQuoteStatusInput!
  $condition: ModelQuoteStatusConditionInput
) {
  updateQuoteStatus(input: $input, condition: $condition) {
    id
    name
    order
    updatedBy
    createdBy
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    ActionByQuoteStatuses {
      items {
        id
        ActionQuoteStatus {
          id
          name
          description
          updatedBy
          createdBy
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          actionQuoteStatusSystemCodeId
          __typename
        }
        quotestatusID
        createdBy
        updatedBy
        createdAt
        updatedAt
        actionByQuoteStatusActionQuoteStatusId
        __typename
      }
      nextToken
      __typename
    }
    templateEmail
    isSaveTypeChange
    createdAt
    updatedAt
    quoteStatusSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuoteStatusMutationVariables,
  APITypes.UpdateQuoteStatusMutation
>;
export const deleteQuoteStatus = /* GraphQL */ `mutation DeleteQuoteStatus(
  $input: DeleteQuoteStatusInput!
  $condition: ModelQuoteStatusConditionInput
) {
  deleteQuoteStatus(input: $input, condition: $condition) {
    id
    name
    order
    updatedBy
    createdBy
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    ActionByQuoteStatuses {
      items {
        id
        ActionQuoteStatus {
          id
          name
          description
          updatedBy
          createdBy
          SystemCode {
            id
            value
            entity
            field
            label
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          actionQuoteStatusSystemCodeId
          __typename
        }
        quotestatusID
        createdBy
        updatedBy
        createdAt
        updatedAt
        actionByQuoteStatusActionQuoteStatusId
        __typename
      }
      nextToken
      __typename
    }
    templateEmail
    isSaveTypeChange
    createdAt
    updatedAt
    quoteStatusSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuoteStatusMutationVariables,
  APITypes.DeleteQuoteStatusMutation
>;
export const createProductStatus = /* GraphQL */ `mutation CreateProductStatus(
  $input: CreateProductStatusInput!
  $condition: ModelProductStatusConditionInput
) {
  createProductStatus(input: $input, condition: $condition) {
    id
    name
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductStatusMutationVariables,
  APITypes.CreateProductStatusMutation
>;
export const updateProductStatus = /* GraphQL */ `mutation UpdateProductStatus(
  $input: UpdateProductStatusInput!
  $condition: ModelProductStatusConditionInput
) {
  updateProductStatus(input: $input, condition: $condition) {
    id
    name
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductStatusMutationVariables,
  APITypes.UpdateProductStatusMutation
>;
export const deleteProductStatus = /* GraphQL */ `mutation DeleteProductStatus(
  $input: DeleteProductStatusInput!
  $condition: ModelProductStatusConditionInput
) {
  deleteProductStatus(input: $input, condition: $condition) {
    id
    name
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductStatusMutationVariables,
  APITypes.DeleteProductStatusMutation
>;
export const createStore = /* GraphQL */ `mutation CreateStore(
  $input: CreateStoreInput!
  $condition: ModelStoreConditionInput
) {
  createStore(input: $input, condition: $condition) {
    id
    name
    link
    logo
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    storeSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStoreMutationVariables,
  APITypes.CreateStoreMutation
>;
export const updateStore = /* GraphQL */ `mutation UpdateStore(
  $input: UpdateStoreInput!
  $condition: ModelStoreConditionInput
) {
  updateStore(input: $input, condition: $condition) {
    id
    name
    link
    logo
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    storeSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStoreMutationVariables,
  APITypes.UpdateStoreMutation
>;
export const deleteStore = /* GraphQL */ `mutation DeleteStore(
  $input: DeleteStoreInput!
  $condition: ModelStoreConditionInput
) {
  deleteStore(input: $input, condition: $condition) {
    id
    name
    link
    logo
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    storeSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStoreMutationVariables,
  APITypes.DeleteStoreMutation
>;
export const createSystemCode = /* GraphQL */ `mutation CreateSystemCode(
  $input: CreateSystemCodeInput!
  $condition: ModelSystemCodeConditionInput
) {
  createSystemCode(input: $input, condition: $condition) {
    id
    value
    entity
    field
    label
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSystemCodeMutationVariables,
  APITypes.CreateSystemCodeMutation
>;
export const updateSystemCode = /* GraphQL */ `mutation UpdateSystemCode(
  $input: UpdateSystemCodeInput!
  $condition: ModelSystemCodeConditionInput
) {
  updateSystemCode(input: $input, condition: $condition) {
    id
    value
    entity
    field
    label
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSystemCodeMutationVariables,
  APITypes.UpdateSystemCodeMutation
>;
export const deleteSystemCode = /* GraphQL */ `mutation DeleteSystemCode(
  $input: DeleteSystemCodeInput!
  $condition: ModelSystemCodeConditionInput
) {
  deleteSystemCode(input: $input, condition: $condition) {
    id
    value
    entity
    field
    label
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSystemCodeMutationVariables,
  APITypes.DeleteSystemCodeMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    name
    email
    phoneNumber
    imgURL
    isAdmin
    gender
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    identification
    identificationType
    createdBy
    updatedBy
    BillInfo {
      id
      name
      address
      identification
      email
      createdBy
      updatedBy
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    userSystemCodeId
    userBillInfoId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    name
    email
    phoneNumber
    imgURL
    isAdmin
    gender
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    identification
    identificationType
    createdBy
    updatedBy
    BillInfo {
      id
      name
      address
      identification
      email
      createdBy
      updatedBy
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    userSystemCodeId
    userBillInfoId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    name
    email
    phoneNumber
    imgURL
    isAdmin
    gender
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    identification
    identificationType
    createdBy
    updatedBy
    BillInfo {
      id
      name
      address
      identification
      email
      createdBy
      updatedBy
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    userSystemCodeId
    userBillInfoId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createProductType = /* GraphQL */ `mutation CreateProductType(
  $input: CreateProductTypeInput!
  $condition: ModelProductTypeConditionInput
) {
  createProductType(input: $input, condition: $condition) {
    id
    name
    createdBy
    updatedBy
    Tax {
      id
      amount
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    productTypeTaxId
    productTypeSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductTypeMutationVariables,
  APITypes.CreateProductTypeMutation
>;
export const updateProductType = /* GraphQL */ `mutation UpdateProductType(
  $input: UpdateProductTypeInput!
  $condition: ModelProductTypeConditionInput
) {
  updateProductType(input: $input, condition: $condition) {
    id
    name
    createdBy
    updatedBy
    Tax {
      id
      amount
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    productTypeTaxId
    productTypeSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductTypeMutationVariables,
  APITypes.UpdateProductTypeMutation
>;
export const deleteProductType = /* GraphQL */ `mutation DeleteProductType(
  $input: DeleteProductTypeInput!
  $condition: ModelProductTypeConditionInput
) {
  deleteProductType(input: $input, condition: $condition) {
    id
    name
    createdBy
    updatedBy
    Tax {
      id
      amount
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    SystemCode {
      id
      value
      entity
      field
      label
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    productTypeTaxId
    productTypeSystemCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductTypeMutationVariables,
  APITypes.DeleteProductTypeMutation
>;
export const createWeightType = /* GraphQL */ `mutation CreateWeightType(
  $input: CreateWeightTypeInput!
  $condition: ModelWeightTypeConditionInput
) {
  createWeightType(input: $input, condition: $condition) {
    id
    name
    abbreviation
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWeightTypeMutationVariables,
  APITypes.CreateWeightTypeMutation
>;
export const updateWeightType = /* GraphQL */ `mutation UpdateWeightType(
  $input: UpdateWeightTypeInput!
  $condition: ModelWeightTypeConditionInput
) {
  updateWeightType(input: $input, condition: $condition) {
    id
    name
    abbreviation
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWeightTypeMutationVariables,
  APITypes.UpdateWeightTypeMutation
>;
export const deleteWeightType = /* GraphQL */ `mutation DeleteWeightType(
  $input: DeleteWeightTypeInput!
  $condition: ModelWeightTypeConditionInput
) {
  deleteWeightType(input: $input, condition: $condition) {
    id
    name
    abbreviation
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWeightTypeMutationVariables,
  APITypes.DeleteWeightTypeMutation
>;
export const createTax = /* GraphQL */ `mutation CreateTax(
  $input: CreateTaxInput!
  $condition: ModelTaxConditionInput
) {
  createTax(input: $input, condition: $condition) {
    id
    amount
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaxMutationVariables,
  APITypes.CreateTaxMutation
>;
export const updateTax = /* GraphQL */ `mutation UpdateTax(
  $input: UpdateTaxInput!
  $condition: ModelTaxConditionInput
) {
  updateTax(input: $input, condition: $condition) {
    id
    amount
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaxMutationVariables,
  APITypes.UpdateTaxMutation
>;
export const deleteTax = /* GraphQL */ `mutation DeleteTax(
  $input: DeleteTaxInput!
  $condition: ModelTaxConditionInput
) {
  deleteTax(input: $input, condition: $condition) {
    id
    amount
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaxMutationVariables,
  APITypes.DeleteTaxMutation
>;
